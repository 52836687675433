import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';

const isActiveUntil = (date) => !isPast(addDays(new Date(date), 1));

const specialEvents = [
  {
    href: 'https://contenido.roll-bits.com/cigaretes-after-sex-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-5tv/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cigarettes-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cigarettes-banner.jpg',
    alt: 'Cigarettes After Sex 15 de Octubre',
    descriptionText: 'Cigarettes After Sex: 15 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-15'),
  },
  {
    href: 'https://contenido.roll-bits.com/paty-cantu-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-mzg-cw3-ujy/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paty-cantu-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paty-cantu-banner.jpg',
    alt: 'Paty Cantú 19 de Octubre',
    descriptionText: 'Paty Cantú: 19 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-19'),
  },
  {
    href: 'https://contenido.roll-bits.com/los-azufres-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-v5i-4uk/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/los-azufres-circle-3.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/los-azufres-banner-3.jpg',
    alt: 'Los Azufres 19 de Octubre',
    descriptionText: 'Los Azufres: 19 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-19'),
  },
  {
    href: `https://contenido.roll-bits.com/six-flags-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-7h7-oas-6g7-jmn/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/festival-terror-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/festival-terror-banner.jpg`,
    alt: 'Six Flags del Terror 26 de Octubre',
    descriptionText: `Six Flags del Terror 26 de Octubre`,
    isSlider: true,
    isActive: isActiveUntil('2024-10-26'),
  },
  {
    href: 'https://contenido.roll-bits.com/aurora31oct-2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/aurora-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/aurora-banner.jpg',
    alt: 'Aurora 31 de Octubre',
    descriptionText: 'Aurora: 31 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-31'),
  },
  {
    href: 'https://contenido.roll-bits.com/rollando-con-lacatrina2-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-pcn/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/janitzio-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/janitzio-banner.jpg',
    alt: 'De rol a Janitzio 01 de Noviembre',
    descriptionText: 'De rol a Janitzio: 01 de Noviembre',
    isSlider: true,
    isActive: isActiveUntil('2024-11-01'),
  },
  {
    href: 'https://contenido.roll-bits.com/slipknot-8-nov_-rev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni-vvs-bd0-d49/?nocache=43087',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-banner.jpg`,
    alt: 'Slipknot 08 de Noviembre',
    descriptionText: `Slipknot: 08 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-08'),
  },
  {
    href: 'https://contenido.roll-bits.com/slipknot-9-nov_-rev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni-vvs-bd0/?nocache=39903',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-banner-2.jpg`,
    alt: 'Slipknot 09 de Noviembre',
    descriptionText: `Slipknot: 09 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-09'),
  },
  {
    href: 'https://contenido.roll-bits.com/paulmccartney-12nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-banner.jpg',
    alt: 'Paul McCartney 12 de Noviembre',
    descriptionText: `Paul McCartney: 12 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-12'),
  },
  {
    href: 'https://contenido.roll-bits.com/paulmccartney-14nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-banner-2.jpg',
    alt: 'Paul McCartney 14 de Noviembre',
    descriptionText: 'Paul McCartney: 14 de Noviembre',
    isSlider: true,
    isActive: isActiveUntil('2024-11-14'),
  },
  {
    href: 'https://contenido.roll-bits.com/fig-2024-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-7h7-oas-6g7/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/fig-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/fig-banner.jpg',
    alt: 'FIG (Festival Internacional del Globo): 16 de Noviembre',
    descriptionText: 'FIG (Festival Internacional del Globo): 16 de Noviembre',
    isSlider: true,
    isActive: isActiveUntil('2024-11-16'),
  },
  {
    href: `https://contenido.roll-bits.com/iron_maiden-qln-x3f-926-oi9-8ap-jpa-hfw-85u-kg1-605-b3u-lqd-zdg-36q-hqq-0tg/?nocache=66700`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/iron-maiden-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/iron-maiden-banner.jpg`,
    alt: 'Iron Maiden 20 Noviembre',
    descriptionText: `Iron Maiden: 20 Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/flow-fest-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-0w1/?nocache=53013',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/flowfest-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/flowfest-banner.jpg`,
    alt: 'Flow Fest 23 y 24 de Noviembre',
    descriptionText: `Flow Fest 23 y 24 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-23'),
  },
  {
    href: 'https://contenido.roll-bits.com/kenia-os-29nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner.jpg',
    alt: 'Kenia 29 de Noviembre',
    descriptionText: `Kenia: 29 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-29'),
  },
  {
    href: 'https://contenido.roll-bits.com/kenia-os-30nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner-2.jpg',
    alt: 'Kenia 30 de Noviembre',
    descriptionText: `Kenia: 30 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-30'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_02_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec/?nocache=17881',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner.jpg`,
    alt: 'Morat 02 Diciembre',
    descriptionText: `Morat: 02 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-02'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_03_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm/?nocache=34006',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-2.jpg`,
    alt: 'Morat 03 Diciembre',
    descriptionText: `Morat: 03 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/real-de-14-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-v5i-4uk-6nw/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/real-catorce-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/real-catorce-banner.jpg',
    alt: 'Real de Catorce 07 de Diciembre',
    descriptionText: 'Real de Catorce: 07 de Diciembre',
    isSlider: true,
    isActive: isActiveUntil('2024-12-07'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_13_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2/?nocache=21741',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-3.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-3.jpg`,
    alt: 'Morat 13 Diciembre',
    descriptionText: `Morat: 13 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-13'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_14_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k/?nocache=85120',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-4.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-4.jpg`,
    alt: 'Morat 14 Diciembre',
    descriptionText: `Morat: 14 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-14'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-20-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat/?nocache=79274',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner.jpg',
    alt: 'Twenty One Pilots 20 de Febrero',
    descriptionText: `Twenty One Pilots: 20 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-22-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi/?nocache=17310',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner-2.jpg',
    alt: 'Twenty One Pilots 22 de Febrero',
    descriptionText: `Twenty One Pilots: 22 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-22'),
  },
];

export default specialEvents;
